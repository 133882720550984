import { Pane, VBox } from '@integration-app/ui/Layout'
import { PropsWithChildren, ReactNode } from 'react'
import { Header } from './Header'

export function ScreenWithHeader({
  leftSlot,
  rightSlot,
  children,
}: PropsWithChildren<{
  leftSlot?: ReactNode
  rightSlot?: ReactNode
}>) {
  return (
    <VBox>
      <Pane>
        <Header rightSlot={rightSlot}>{leftSlot}</Header>
      </Pane>
      <Pane stretch>{children}</Pane>
    </VBox>
  )
}
