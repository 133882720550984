import { PropsWithChildren, ReactNode } from 'react'
import classes from './Header.module.css'

export function Header({
  children,
  rightSlot,
}: PropsWithChildren<{ rightSlot?: ReactNode }>) {
  return (
    <div className={classes.header}>
      <div className={classes.wrapper}>
        {children}
        <div className={classes.spacer} />
        {rightSlot}
      </div>
    </div>
  )
}
